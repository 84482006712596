/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "../../@core/scss/base/bootstrap-extended/include";
@import "../../@core/scss/base/components/include";

[dir] .auth-wrapper .brand-logo img {
  width: 200px;
}

.auth-wrapper.auth-basic {
  background: url(../images/backgrounds/Stock-River-Photo.webp) no-repeat;
  background-position: center center;
  object-fit: cover;
  min-width: 100%;
  background-size: 100%;
}

.vertical-layout .main-menu {
  .navigation {
    a.active {
      background: $primary;
      box-shadow: 0 0 5px 1px rgba($primary, 0.7);
    }
  }
}

.table:not(.table-dark):not(.table-light) thead:not(.table-dark) th {
  background-color: #010101;
  color: $white;
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 172px;
}
[dir="ltr"] .header-navbar.floating-nav {
  right: 30px;
}
.header-navbar.floating-nav {
  width: calc(100vw - (100vw - 25%) - calc(2rem * 2) - 260px);
}
[dir="ltr"] .header-navbar.fixed-top,
[dir="ltr"] .header-navbar.floating-nav {
  left: auto;
}
[dir] .main-menu .navbar-header .navbar-brand {
  margin-top: 1rem;
}
[dir="ltr"] .main-menu .navbar-header {
  padding: 0.35rem 1rem 0.3rem 0.5rem;
}

[dir] .main-menu.menu-light .navigation {
  background: transparent;
}

// [dir] .main-menu.menu-light {
//   background-image: url("../images/backgrounds/rivers-min.jpg");
//   background-repeat: no-repeat;
//   background-size: cover;
// }

.title-info {
  h3 {
    font-size: 27px;
    text-transform: uppercase;
    color: #010101;
    font-weight: 700;
  }
}

.table {
  color: #010101;
  &.table-hover > tbody > tr:hover {
    color: #010101;
  }
}

[dir] .form-label {
  font-size: 12px;
  font-weight: 600;
  color: #010101;
}

// dorpzone

.dropzone {
  min-height: auto !important;
}

.custom_dropzone {
  min-height: 350px !important;
}
.main-menu .main-menu-content {
  border-top: 1px solid rgb(255 255 255 / 20%);
  height: calc(100% - 9rem) !important;
}
.navbar-header.nav-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 15px 10px 15px;
  display: flex;
  align-items: center;
  & .avatar {
    background-color: $primary;
    & .avatar-content {
      width: 50px;
      height: 50px;
      font-size: 1.5rem;
    }
  }
  ul {
    width: 100%;
  }
}
.nav-footer .nav-item.navbar-brand {
  // width: 100%;
  margin-right: 0;
}
.nav-footer .user-info {
  display: flex;
  align-items: center;
  // width: 100%;
}
.nav-footer.navbar-header .navbar-brand {
  // width: 100%;
  justify-content: space-between;
  margin-right: 0;
  margin-top: 0rem;
}
.main-menu .navbar-header.nav-footer .navbar-brand .brand-text {
  font-size: 16px;
  color: #010101;
}
html[dir] .content.app-content {
  padding: 2rem 2rem 0;
}

.validation_error_cls {
  font-size: 12px;
  color: red;
}

.custom-input-date-picker {
  position: relative;
}
.custom-date-picker-close-icon {
  position: absolute;
  right: 5px;
  top: 12px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  // border: 1px solid gray;
}

.average-flow-rate-date-tooltip {
  color: $primary;
}
